import { Component, OnInit } from '@angular/core';
import { getGlobalDisplayCategory } from 'src/app/app.component';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public getDisplayCategory(){
    return getGlobalDisplayCategory();
  }

}
