<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">What I did so far</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">My Experience</h2>
<div class="experience">
						
    <!-- Experience Item -->
    <div style="display:table;">
        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">07.2020-Present</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">07.2020-Present</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">VFX/ Motion Design Artist</div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">NAMOTO GmbH & Co. KG (Former part of NEVEREST, Serviceplan Agenturgruppe)</div>
                <p>3D- und 2D- Animation, MotionDesign, Modelling, Compositing, Storyboarding, 
                    Concept Art, 2D- und 3D- Tracking, Rotoscoping, Keying, Set Supervision, 
                    Beauty Retouche,</p>
            </div>
        </div>

        <br>

        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">05.2018-06.2020</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">05.2018-06.2020</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">VFX/ Motion Design Artist</div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">NEVEREST GmbH & Co. KG (Serviceplan Agenturgruppe)</div>
                <p>3D- und 2D- Animation, MotionDesign, Modelling, Texturing, Compositing, 
                    Storyboardind, Con 2D- und 3D- Tracking, Rotoscoping, Set Supervision, 
                    Beauty Retouche,</p>
            </div>
        </div>

        <br>

        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">04.2016-04.2018</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">04.2016-04.2018</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">Junior VFX/ Motion Design Artist</div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">NEVEREST GmbH & Co. KG (Serviceplan Agenturgruppe)</div>
                <p>3D- und 2D- Animation, MotionDesign, Modelling, Texturing, Compositing, Storyboardind, 
                    Concept Art, Tracking, Rotoscoping, Keying, Set Supervision</p>
            </div>
        </div>

        <br>

        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">03.2015-03.2016</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">03.2015-03.2016</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">Freelancer VFX/ Motion Design</div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">NEVEREST GmbH & Co. KG (Serviceplan Agenturgruppe)
                    <br>
                    CSW Germany</div>
                <p></p>
            </div>
        </div>

        <br>

        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">08.2014-02.2015</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">08.2014-02.2015</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">Internship VFX/ Motion Design Artist </div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">NEVEREST GmbH & Co. KG (Serviceplan Agenturgruppe)</div>
                <p>3D-, 2D- und typographische Animation, Modelling,Texturing, Compositing, Storyboardind, 
                    Concept Art, Tracking, Rotoscoping </p>
            </div>
        </div>

        <h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Award</h3>

        <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">2019</div>
        <div style="display:table-row">
            <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">2019</div>
            <div style="display: table-cell;">
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">RedDot Grandprix 2019</div>
                <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">The Wedding of Siri & Alexa</div>
                <p>Film Production
                </p>
            </div>
        </div>

    </div>

</div>
