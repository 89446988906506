import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild('sidebar') sidebarElement: ElementRef;

  aboutVisible: boolean;
  skillsVisible: boolean;
  experienceVisible: boolean;
  trainingVisible: boolean;
  portfolioVisible: boolean;
  contactVisible: boolean;
  

  title = 'angular-website';

  innerWidth:number;
  innerHeight:number;

  menuActive:boolean;

  constructor(private renderer:Renderer2, private viewportScroller: ViewportScroller){}

  ngOnInit(){
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  public goTo(elementId:string){
    this.viewportScroller.scrollToAnchor(elementId);
  }

  public getDisplayCategory(){
    return getGlobalDisplayCategory();
    /*if(this.innerWidth < 768){
      return "sm"
    } else if (this.innerWidth < 992){
      return "md"
    } else if (this.innerWidth < 1200){
      return "lg"
    } else {
      return "xl"
    }*/
  }

  getImgMargin(){
    let category:string = this.getDisplayCategory()
    switch(category){
      case "lg":
        return 20;
      default:
        return -4;
    }
  }

  public getInfoHeight(){
    let category:string = this.getDisplayCategory()
    switch(category){
      case "sm":
        return this.innerHeight * 0.75;
      case "md":
        return ((this.innerWidth/2)-10);
      default:
        return this.innerHeight - (this.innerWidth/4) - 100;
    }
  }

  public setMenuActive(){
    this.menuActive=true;
    if(this.getDisplayCategory() == "sm"){
      this.renderer.setStyle(this.sidebarElement.nativeElement, 'animation-name', 'slideInPhone');
    } else {
      this.renderer.setStyle(this.sidebarElement.nativeElement, 'animation-name', 'slideInTab');
    }
  }

  public setMenuInactive(){
    this.menuActive=false;
    if(this.getDisplayCategory() == "sm"){
      this.renderer.setStyle(this.sidebarElement.nativeElement, 'animation-name', 'slideOutPhone');
    } else {
      this.renderer.setStyle(this.sidebarElement.nativeElement, 'animation-name', 'slideOutTab');
    }
  }

  public setVisible(key:string, { target, visible }: { target: Element; visible: boolean }){
    switch(key){
      case "about":
        this.aboutVisible = visible;
        return;
      case "skills":
        this.skillsVisible = visible;
        return;
      case "experience":
        this.experienceVisible = visible
        return;
      case "training":
        this.trainingVisible = visible
        return;
      case "portfolio":
        this.portfolioVisible = visible
        return;
      case "contact":
        this.contactVisible = visible;
        return;
      default: 
        return;
    }
  }

  public getContainerSpacing(){
    if(this.getDisplayCategory() == "sm" || this.getDisplayCategory() == "md"){
      return 21;
    } return 0;
  }

  getSidebarMarging(){
    if(this.getDisplayCategory() == "sm"){
      return "15vw";
    } return "5vw";
  }

  public getNavState(key:string){
    switch(key){
      case "about":
        if(this.aboutVisible){
          return "#b3b3b3";
        }
        return "#592750";
      case "skills":
        if(!this.aboutVisible && this.skillsVisible){
          return "#b3b3b3";
        }
        return "#592750";
      case "experience":
        if(!this.skillsVisible && this.experienceVisible){
          return "#b3b3b3";
        }
        return "#592750";
      case "training":
        if(!this.experienceVisible && this.trainingVisible){
          return "#b3b3b3";
        }
        return "#592750";
      case "portfolio":
        if(!this.trainingVisible &&this.portfolioVisible){
          return "#b3b3b3";
        }
        return "#592750";
      case "contact":
        if(!this.portfolioVisible && this.contactVisible){
          return "#b3b3b3";
        }
        return "#592750";
      default: 
        return "#592750";
    }
  }

  public getMEAT(){
    if(this.getDisplayCategory() == "xl" || this.getDisplayCategory() == "lg"){
      return "MEAT";
    } else {
      return "veggie";
    }
  }

  public doMagic(){
    console.log("something magical just happened");
  }

  /*public static getDisplayCategory(){
    let innerWidth = window.innerWidth;
    if(innerWidth < 768){
      return "sm"
    } else if (innerWidth < 992){
      return "md"
    } else if (innerWidth < 1200){
      return "lg"
    } else {
      return "xl"
    }
  }*/
}

export function getGlobalDisplayCategory(){
  let innerWidth = window.innerWidth;
  if(innerWidth < 768){
    return "sm"
  } else if (innerWidth < 992){
    return "md"
  } else if (innerWidth < 1200){
    return "lg"
  } else {
    return "xl"
  }
}

