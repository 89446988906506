import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  dialogEnabled:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showDialog(){
    this.dialogEnabled=true;
  }

}
