import { Component, OnInit } from '@angular/core';
import { getGlobalDisplayCategory } from "../../app.component";

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getIFrameWidth(){
    let category:string = getGlobalDisplayCategory();
    switch(category){
      case "sm":
        return "100%";
        
      case "md":
        return "45%";
        
      case "lg":
        return "45%";
        
      default:
        return "45%";
    }
  }
  
  public getDisplayCategory(){
    return getGlobalDisplayCategory();
  }
}
