import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InViewportModule } from 'ng-in-viewport';

//primeng imports:

import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { AboutComponent } from './chapters/about/about.component';
import { SkillsComponent } from './chapters/skills/skills.component';
import { ExperienceComponent } from './chapters/experience/experience.component';
import { TrainingComponent } from './chapters/training/training.component';
import { PortfolioComponent } from './chapters/portfolio/portfolio.component';
import { ContactComponent } from './chapters/contact/contact.component';
import { GeneralComponent } from './general/general.component';
import { ImpressumComponent } from './impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    SkillsComponent,
    ExperienceComponent,
    TrainingComponent,
    PortfolioComponent,
    ContactComponent,
    GeneralComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    InViewportModule,

    //primeng components
    SidebarModule,
    DialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
