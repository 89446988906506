<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">VFX & CGI Artis</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">Ines Katharina Kossack</h2>
<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Description</h3>
<p>Nice to meet you! 
    <br>
    I have been working for more than 6 Years as a VFX and Motion Design Artist for the Serviceplan Group.
    <br>
    <br>
    If you are looking for the best fitting adjectives to describe me? 
    <br>
    Motivated, organized, creative, ambitious, kind and communicative.
    <br>
    <br>
    Working independently and as part of a Team, creating solutions for problems I never encountered before, 
    is part of my daily work. 
    <br>
    <br>
    I studied "Intractive Media" at the University of Applied Sciences Augsburg.
    <br>
    During my studies I leared programming, creating and designing websites, usertesting and the basics 
    of UX/ UI Design. 
</p>
<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Clients</h3>
<p>BMW, ABB, Ravensburger, Hilti, Aldi, Brandt, M.Asam, Penny, Hexal, Lascana, HiPP, Explora, HSE24, Lieferhelt, 
    Florett, Adoptyfy, WMF, Flaconi, AboutYou, CleverFit, Rodenstock, Eurojackpot, AOK, Consorsbank, Deutschland Card,
    Disney, Emma, Ottonova, Senseo, Landliebe, Sky, Vorwerk uvm.</p>
<br>
<br>
<br>



