<div class = "p-grid" style="overflow:hidden;">
  <!-- Top Menu -->
  <div class = "p-col-12 p-grid title">
    
    <!--title-->
    <div class  = "p-col-12 p-md-12 p-lg-4 p-xl-3" 
      style     = "text-align: center;">
      InesKatherina
    </div>

    <!--navigation-->
    <div class  = "p-col-12 p-md-12 p-lg-8 p-xl-9"
      *ngIf     = "(getDisplayCategory() != 'sm') &amp;&amp; (getDisplayCategory() != 'md')"
      style     = "display: flex; justify-content: space-evenly;">
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('about')}">
        <a href = "#about">About</a>
      </span>
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('skills')}">
        <a href = "#skills">Skills</a>
      </span>
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('experience')}">
        <a href = "#experience">Experience</a>
      </span>
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('training')}">
        <a href = "#training">Education</a>
      </span>
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('portfolio')}">
        <a href = "#portfolio">Portfolio</a>
      </span>
      <span class = "navigationButton" 
        [ngStyle] = "{'color': getNavState('contact')}">
        <a href = "#contact">Contact</a>
      </span>
    </div>
  </div>
  
  <!--Sidebar-->
  <div class  = "p-col-12 p-md-12 p-lg-4 p-xl-3 p-grid" 
    style     = "padding:0; background-color:#592750; margin-top:42px; overflow:hidden;">
    <div class = "p-col-12 p-md-6 p-lg-12 p-xl-12" [style.margin-bottom.px]="getImgMargin()">
      <img src = "assets/kossack.jpg">
    </div>
    <div class  = "p-col-12 p-md-6 p-lg-12 p-xl-12" 
      [style.height.px] = "getInfoHeight()">
      <app-general></app-general>
    </div>
  </div>
  
  <!-- Meat =P -->
  <div class  = "p-col-12 p-md-12 p-lg-8 p-xl-9" 
    [class]   = "getMEAT()">
    <div [style.padding-top.px]="getContainerSpacing()"  id = "about"      inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('about', $event)"><app-about></app-about></div>
    <div [style.padding-top.px]="getContainerSpacing()"  id = "skills"     inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('skills', $event)"><app-skills></app-skills></div>
    <div [style.padding-top.px]="getContainerSpacing()"  id = "experience" inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('experience', $event)"><app-experience></app-experience></div>
    <div [style.padding-top.px]="getContainerSpacing()"  id = "training"   inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('training', $event)"><app-training></app-training></div>
    <div [style.padding-top.px]="getContainerSpacing()"  id = "portfolio"  inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('portfolio', $event)"><app-portfolio></app-portfolio></div>
    <div [style.padding-top.px]="getContainerSpacing()"  id = "contact"    inViewport [inViewportOptions]="{ partial: true, threshold: [0, 1] }" (inViewportAction)="setVisible('contact', $event)"><app-contact></app-contact></div>
  </div>
</div>



<!-- Overlays and Menu Buttons -->
<div *ngIf="(getDisplayCategory() == 'sm') || (getDisplayCategory() == 'md')" class="menuButton" (click)="setMenuActive()">
  <i class="pi pi-bars"></i>
</div>

<div #sidebar class="sidebar">
  <div *ngIf  = "menuActive" 
    class     = "menuButton" 
    (click)   = "setMenuInactive()">
    <i class = "pi pi-times"></i>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('about'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#about">About</a>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('skills'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#skills">Skills</a>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('experience'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#experience">Experience</a>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('training'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#training">Education</a>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('portfolio'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#portfolio">Portfolio</a>
  </div>
  <div class = "sidebarNavigationButton" 
    [ngStyle] = "{'color': getNavState('contact'), 'margin-left': getSidebarMarging()}">
    <a (click)="setMenuInactive()" href = "#contact">Contact</a>
  </div>
</div>

<div *ngIf  = "menuActive" 
  class     = "sidebarOverlay" 
  (click)   = "setMenuInactive()">
</div>