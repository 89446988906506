import { Component, OnInit } from '@angular/core';
import { getGlobalDisplayCategory } from 'src/app/app.component';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public getDisplayCategory(){
    return getGlobalDisplayCategory();
  }
}
