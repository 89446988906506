
<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">What I am good at</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">My Skills</h2>

<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Software</h3>
<div class="p-grid">
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Maxon Cinema 4D</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Resshift Renderer</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">BlackMagic Fusion</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">BorisFX MochaPro</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Adobe AfterEffects</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Adobe Photoshop</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Adobe Illustrator</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Adobe InDesign</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Davinci Resolve</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">SideFX Houdini</div>
</div>
<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Techniques</h3>
<div class="p-grid">
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Modeling</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Rigging</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Animation</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Keying</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Rotoscoping</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Retouche</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Conception</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Storyboarding</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Compositing</div>
</div>

<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Softskills</h3>
<div class="p-grid">
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Mentoring</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Set Supervison</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Flexability</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Spanish B1</div>

    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Custumer orientation</div>

    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Quick and efficent workflow adaptation</div>


</div>    
<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Other Intrests</h3>
<p>Thinks I enjoy doing outside of work.</p>
<div class="p-grid">
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">My Dog</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Learning the piano</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Studying UX Design</div>
    <div class="p-col-6 p-md-4 p-lg-3 p-xl-3 line">Yoga</div>
</div>
<br>
<br>
<br>
<br>