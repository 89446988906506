<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">That's how it looks like</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">My Portfolio</h2>

<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Showreel</h3>

<p>Is still in progess</p>

<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Commercials</h3>

<p>I will show you some of the projects I worked on during my time at Neverest and Namoto hopefully in a short while.</p>

<!--div class="portfolioContent">
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/269817340"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/432100794"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/410972935"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/410972645"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/402880413"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/388233543"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>



    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/380257241"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/425902060"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/373883722"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/368754707"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/361749391"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/361291663"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>    



    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/357572879"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/333323905"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/318181069"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/307282942"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/302250748"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/270043437"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>



    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/255702920"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/254305442"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/250092932"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/250129444"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/210254668"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/208274902"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>



    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/182687288"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/153350051"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/203259924"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</div>
<div class="clear"></div>
<br>
<br>

<h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Break Downs</h3>

<div class="portfolioContent">
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/311260267"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
    <div [class] = "(getDisplayCategory() == 'sm') ? 'iframe-container-big' : 'iframe-container' ">
        <iframe src="https://player.vimeo.com/video/224287482"      allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</div>

<div class="clear"></div-->

<br>
<br>
<br>
<br>
<br>
<br>
<br>