<div style="text-align: center; margin-top: 40px;">
    <div class="general_info_title">General Information</div>
    <div style="display:table; margin: 0 auto">
        <div style="display:table-row;">
            <div class="iconCell"><img src="assets/icon_1.png" alt=""></div> <div class="labelCell">Name:</div> <div class="contentCell"><span>Ines Katharina Kossack</span></div>
        </div>
        <div style="display:table-row">
            <div class="iconCell"><img src="assets/icon_0.png" alt=""></div> <div class="labelCell">Location:</div> <div class="contentCell"><span>Munich DE</span></div>
        </div>
        <div style="display:table-row">
            <div class="iconCell"><img src="assets/icon_2.png" alt=""></div> <div class="labelCell">Date of Birth:</div> <div class="contentCell"><span>September 09, 1991</span></div>
        </div>
        <div style="display:table-row">
            <div class="iconCell"><img src="assets/icon_3.png" alt=""></div> <div class="labelCell">Contact:</div> <div class="contentCell"><a href="mailto:info@ineskatharina.de?subject=Mail%20from%20Website">info@ineskatharina.de</a></div>
        </div>
    </div>
    <div class="links">
        <a href="https://www.linkedin.com/in/ineskatharinakossack/"><img src="assets/linkedin.png" alt=""></a>
        <a href="https://www.xing.com/profile/InesKatharina_Kossack/"><img src="assets/xing.png" alt=""></a>
        <a href="https://www.behance.net/ineskossack"><img src="assets/behance.png" alt=""></a>
        <div (click)="showDialog()"><p>Imprint</p></div>
        <br>
    </div>

</div>

<p-dialog header="Imprint" [style]="{width: '50vw', height: '50vh'}" [(visible)]="dialogEnabled">
    <div style="width: 100%; height: 100%; overflow-y: scroll;">
        <app-impressum></app-impressum>
    </div>
</p-dialog>

<!--div class="general_info_content">
    <div class="general_info_content_inner mCustomScrollbar" data-mcs-theme="minimal-dark">
        <div class="general_info_title">General Information</div>
        
        <ul class="general_info_list">
            <li class="d-flex flex-row align-items-center justify-content-start">
                <div class="general_info_text"><img src="assets/icon_1.png" alt="">&nbsp;&nbsp;&nbsp;&nbsp;Name: <span>Ines Katharina Kossack</span></div>
            </li>
            <li class="d-flex flex-row align-items-center justify-content-start">
                <div class="general_info_text"><img src="assets/icon_0.png" alt="">&nbsp;&nbsp;&nbsp;&nbsp;Location: <span>Munich DE</span></div>
            </li>
            <li class="d-flex flex-row align-items-center justify-content-start">
                <div class="general_info_text"><img src="assets/icon_2.png" alt="">&nbsp;&nbsp;&nbsp;&nbsp;Date of Birth: <span>September 09, 1991</span></div>
            </li>
            <li class="d-flex flex-row align-items-center justify-content-start">
                <div class="general_info_text"><img src="assets/icon_3.png" alt="">&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:contact@linque.com?subject=Job_Inquiry">info@ineskatharina.de</a></div>
            </li>
        </ul>

        <div class="social_container">
            <ul class="d-flex flex-row align-items-start justify-content-start">
                <li><a href="https://www.behance.net/ineskossack"><i class="fa fa-behance" aria-hidden="true"></i></a></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    </div>
</div-->