<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">Where I learned it</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">My Education</h2>


<!-- Experience Item -->
<div style="display:table;">
    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">10.2012 - 07.2016</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">10.2012 - 07.2016</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> Bachelor's degree program Interactive Media</div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">University of Applied Sciences</div>
            <p>Animation, 3D, Visual Concepts, Sketching, Webdesign, 
                Multimedia Applications Programming and Design, Web Development, 
                Softwaredevelopment and Programming, Software Engineering, Database, 
                Computersystems und Algorithms
            </p>
        </div>
    </div>

    <br>

    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">09.2011 - 07.2012</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">09.2011 - 07.2012</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> German Abitur (General University entrance qualification) </div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">University of Applied Sciences</div>
            <p>Focus on german, english, mathamatics, economocs and law
            </p>
        </div>
    </div>

    <br>

    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">09.2008 – 07.2010</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">09.2008 – 07.2010</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> German Fachabitur (Specialized University entrance qualification) </div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">University of Applied Sciences</div>
            <p>Focus on german, english, mathamatics, economocs and law
            </p>
        </div>
    </div>

    <br>

    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">09.2002 – 07.2008</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">09.2002 – 07.2008</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> German Mittlere Reife (O-level) </div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">Staatliche Realschule Unterpfaffenhofen in Germering</div>
            <p>Focus on german, english, mathamatics, economocs
            </p>
        </div>
    </div>

    <h3 [class] = "(getDisplayCategory() == 'sm') ? 'h3-small' : 'h3-standard' ">Further Training</h3>

    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">05.2019</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">05.2019</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> UXQB Certified Professional for User Experience</div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">Certible</div>
            <p>Foundation Level (CPUX-F)
            </p>
        </div>
    </div>

    <br>
    
    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time-small">11.2018</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">11.2018</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'"> Masterseminar: Product Design, Management, Usability & Conventions</div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">Steinbeis University</div>
            <p>
                Theory:
                <br>
                    History, Basics, Definition and Conventions for Design in general as well as Produktdesign and User Experience specifically
                    <br>
                    Design Leadership and Design Management
                    <br>
                    Design Thinking as corporate culture ...
                <br>
                <br>
                In practice:
                <br>
                    How to ask the right questions for your project?
                    <br>
                    How to fokus on the human during the productdevelopment process?
                    <br>
                    How to integrate the users aktivly in the productdevelopment process?...
            </p>
        </div>
    </div>

    <br>

    <div *ngIf="getDisplayCategory() == 'sm'" style="display:table-row; text-align: left; height:4vh;" class="exp_time">06.2010-07.2011</div>
    <div style="display:table-row">
        <div *ngIf="getDisplayCategory() != 'sm'" style="display: table-cell;" class="exp_time">06.2010-07.2011</div>
        <div style="display: table-cell;">
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_title-small' : 'exp_title'">Student exchange program</div>
            <div [class]="(getDisplayCategory() == 'sm') ? 'exp_subtitle-small' : 'exp_subtitle'">Costa Rica</div>
            <p>I lived a year in Costa Rica with a host family
            </p>
        </div>
    </div>


</div>

<br>
<br>
<br>