<h2 class="adsimple-321203183">Impressum</h2>
<p class="adsimple-321203183">Informationspflicht laut § 5 TMG.</p>
<p class="adsimple-321203183">Ines Katharina<br />Fürstenrieder Str 7, <br />80687 München, <br />Deutschland</p>
<p class="adsimple-321203183">
</p>
<p class="adsimple-321203183">
<strong>Tel.:</strong> 017656517348<br />
<strong>E-Mail:</strong> <a href="mailto:info@ineskatharina.de">info@ineskatharina.de</a>
</p>
<p class="adsimple-321203183">
<strong>Berufsbezeichnung:</strong> Designer</p>
<p style="margin-top:15px;">Quelle: Erstellt mit dem <a title="Impressum Generator Deutschland" href="https://www.adsimple.de/impressum-generator/" target="_blank" rel="follow" style="text-decoration:none;">Impressum Generator</a> von AdSimple in Kooperation mit <a href="https://www.hashtagmann.de" target="_blank" rel="follow" title="">hashtagmann.de</a>
</p>
<h1 class="adsimple-321203183">EU-Streitschlichtung</h1>
<p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter <a class="adsimple-321203183" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE" target="_blank" rel="noopener">http://ec.europa.eu/odr?tid=321203183</a> zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
<p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
<h1 class="adsimple-321203183">Haftung für Inhalte dieser Website</h1>
<p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz <a class="adsimple-321203183" href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321203183" rel="noopener" target="_blank">(TMG) §7 (1)</a> sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
<p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt. </p>
<p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.</p>
<h1 class="adsimple-321203183">Haftung für Links auf dieser Website</h1>
<p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
<p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
<h1 class="adsimple-321203183">Urheberrechtshinweis</h1>
<p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
<h1 class="adsimple-321203183">Bildernachweis</h1>
<p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
<p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
<ul class="adsimple-321203183">
<li class="adsimple-321203183">Fotograf Mustermann</li>
</ul>
<h1 class="adsimple-321203183">Datenschutzerklärung</h1>
<h1 class="adsimple-321203183">Datenschutz</h1>
<p>Wir haben diese Datenschutzerklärung (Fassung 15.08.2020-321203183) verfasst, um Ihnen gemäß der Vorgaben der <a class="adsimple-321203183" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=321203183" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
<p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
<h1 class="adsimple-321203183">Automatische Datenspeicherung</h1>
<p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.</p>
<p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</p>
<ul class="adsimple-321203183">
<li class="adsimple-321203183">die Adresse (URL) der aufgerufenen Webseite</li>
<li class="adsimple-321203183">Browser und Browserversion</li>
<li class="adsimple-321203183">das verwendete Betriebssystem</li>
<li class="adsimple-321203183">die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
<li class="adsimple-321203183">den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
<li class="adsimple-321203183">Datum und Uhrzeit</li>
</ul>
<p>in Dateien (Webserver-Logfiles).</p>
<p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
<h1 class="adsimple-321203183">Rechte laut Datenschutzgrundverordnung</h1>
<p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
<ul class="adsimple-321203183">
<li class="adsimple-321203183">Recht auf Berichtigung (Artikel 16 DSGVO)</li>
<li class="adsimple-321203183">Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
<li class="adsimple-321203183">Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
<li class="adsimple-321203183">Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
<li class="adsimple-321203183">Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
<li class="adsimple-321203183">Widerspruchsrecht (Artikel 21 DSGVO)</li>
<li class="adsimple-321203183">Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
</ul>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a class="adsimple-321203183" class="321203183" href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p>
<h1 class="adsimple-321203183">Google Fonts Datenschutzerklärung</h1>
<p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
<h3 class="adsimple-321203183">Was sind Google Fonts?</h3>
<p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a class="adsimple-321203183" href="https://de.wikipedia.org/wiki/Google_LLC?tid=321203183">Google</a> seinen Nutzern kostenlos zu Verfügung stellt.</p>
<p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
<h3 class="adsimple-321203183">Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
<p>Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</p>
<h3 class="adsimple-321203183">Welche Daten werden von Google gespeichert?</h3>
<p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>
<p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
<p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
<h3 class="adsimple-321203183">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>
<p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
<h3 class="adsimple-321203183">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a class="adsimple-321203183" href="https://support.google.com/?hl=de&amp;tid=321203183">https://support.google.com/?hl=de&amp;tid=321203183</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
<p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a class="adsimple-321203183" href="https://developers.google.com/fonts/faq?tid=321203183">https://developers.google.com/fonts/faq?tid=321203183</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>
<p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a class="adsimple-321203183" href="https://policies.google.com/privacy?hl=de&amp;tid=321203183">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
<h1 class="adsimple-321203183">Eingebettete Social Media Elemente Datenschutzerklärung</h1>
<p>Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.<br />
Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.<br />
Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:</p>
<ul class="adsimple-321203183">
<li class="adsimple-321203183">Instagram-Datenschutzrichtlinie: <a class="adsimple-321203183" href="https://help.instagram.com/519522125107875?tid=321203183" target="_blank" rel="noopener">https://help.instagram.com/519522125107875</a>
</li>
<li class="adsimple-321203183">Für YouTube gilt die Google Datenschutzerklärung: <a class="adsimple-321203183" href="https://policies.google.com/privacy?hl=de&amp;tid=321203183" target="_blank" rel="noopener">https://policies.google.com/privacy?hl=de</a>
</li>
<li class="adsimple-321203183">Facebook-Datenrichtline: <a class="adsimple-321203183" href="https://www.facebook.com/about/privacy?tid=321203183" target="_blank" rel="noopener">https://www.facebook.com/about/privacy</a>
</li>
<li class="adsimple-321203183">Twitter Datenschutzrichtlinie: <a class="adsimple-321203183" href="https://twitter.com/de/privacy?tid=321203183" target="_blank" rel="noopener">https://twitter.com/de/privacy</a>
</li>
</ul>
<h1 class="adsimple-321203183">LinkedIn Datenschutzerklärung</h1>
<p>Wir nutzen auf unserer Webseite Social-Plug-ins des Social-Media-Netzwerks LinkedIn, der Firma LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Bei den Social-Plug-ins kann es sich um Feeds, das Teilen von Inhalten oder um die Verlinkung zu unserer LinkedIn-Seite handeln. Die Social-Plug-ins sind eindeutig mit dem bekannten LinkedIn-Logo gekennzeichnet und erlauben beispielsweise interessante Inhalte direkt über unsere Webseite zu teilen. Für den Europäischen Wirtschaftsraum und die Schweiz ist die Firma LinkedIn Ireland Unlimited Company Wilton Place in Dublin für die Datenverarbeitung verantwortlich.</p>
<p>Durch die Einbettung solcher Plug-ins können Daten an LinkedIn versandt, gespeichert und dort verarbeitet werden. In dieser Datenschutzerklärung wollen wir Sie informieren, um welche Daten es sich handelt, wie das Netzwerk diese Daten verwendet und wie Sie die Datenspeicherung verwalten bzw. unterbinden können.</p>
<h3 class="adsimple-321203183">Was ist LinkedIn?</h3>
<p>LinkedIn ist das größte soziale Netzwerk für Geschäftskontakte. Anders als beispielsweise bei Facebook konzentriert sich das Unternehmen ausschließlich auf den Aufbau geschäftlicher Kontakte. Unternehmen können auf der Plattform Dienstleistungen und Produkte vorstellen und Geschäftsbeziehungen knüpfen. Viele Menschen verwenden LinkedIn auch für die Jobsuche oder um selbst geeignete Mitarbeiter oder Mitarbeiterinnen für die eigene Firma zu finden. Allein in Deutschland zählt das Netzwerk über 11 Millionen Mitglieder. In Österreich sind es etwa 1,3 Millionen.</p>
<h3 class="adsimple-321203183">Warum verwenden wir LinkedIn auf unserer Webseite?</h3>
<p>Wir wissen wie beschäftigt Sie sind. Da kann man nicht alle Social-Media-Kanäle einzeln verfolgen. Auch wenn es sich, wie in unserem Fall, lohnen würde. Denn immer wieder posten wir interessante News oder Berichte, die es wert sind, verbreitet zu werden. Darum haben wir auf unserer Webseite die Möglichkeit geschaffen, interessante Inhalte direkt auf LinkedIn zu teilen bzw. direkt auf unsere LinkedIn-Seite zu verweisen. Wir betrachten eingebaute Social-Plug-ins als erweiterten Service auf unserer Webseite. Die Daten, die LinkedIn sammelt, helfen uns zudem mögliche Werbemaßnahmen nur Menschen zu zeigen, die sich für unser Angebot interessieren.</p>
<h3 class="adsimple-321203183">Welche Daten werden von LinkedIn gespeichert?</h3>
<p>Nur durch die bloße Einbindung der Social-Plug-ins speichert LinkedIn keine persönlichen Daten. LinkedIn nennt diese Daten, die durch Plug-ins generiert werden, passive Impressionen. Wenn Sie aber auf ein Social-Plug-in klicken, um beispielsweise unsere Inhalte zu teilen, speichert die Plattform personenbezogene Daten als sogenannte „aktive Impressionen“. Und zwar unabhängig, ob Sie ein LinkedIn-Konto haben oder nicht. Falls Sie angemeldet sind, werden die erhobenen Daten Ihrem Konto zugeordnet.</p>
<p>Ihr Browser stellt eine direkte Verbindung zu den Servern von LinkedIn her, wenn Sie mit unseren Plug-ins interagieren. So protokolliert das Unternehmen verschiedene Nutzungsdaten. Neben Ihrer IP-Adresse können das beispielsweise Anmeldungsdaten, Gerätinformationen oder Infos über Ihren Internet- bzw. Mobilfunkanbieter sein. Wenn Sie LinkedIn-Dienste über Ihr Smartphone aufrufen, kann auch Ihr Standort (nachdem Sie das erlaubt haben) ermittelt werden. LinkedIn kann diese Daten in „gehashter“ Form auch an dritte Werbetreibende weitergeben. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Daten so verschlüsseln, dass Personen nicht mehr identifiziert werden können.</p>
<p>Die meisten Daten zu Ihrem Userverhalten werden in Cookies gespeichert. Das sind kleine Text-Dateien, die meist in Ihrem Browser gesetzt werden. Weiters kann LinkedIn aber auch Web Beacons, Pixel-Tags, Anzeige-Tags und andere Geräteerkennungen benutzen.</p>
<p>Diverse Tests zeigen auch welche Cookies gesetzt werden, wenn ein User mit einem Social-Plug-in interagiert. Die gefundenen Daten können keinen Vollständigkeitsanspruch erheben und dienen lediglich als Beispiel. Die folgenden Cookies wurden gesetzt, ohne bei LinkedIn angemeldet zu sein:</p>
<p>
<strong class="adsimple-321203183">Name:</strong> bcookie<br />
<strong class="adsimple-321203183">Wert:</strong> =2&amp;34aab2aa-2ae1-4d2a-8baf-c2e2d7235c16321203183-<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Das Cookie ist ein sogenanntes „Browser-ID-Cookie“ und speichert folglich Ihre Identifikationsnummer (ID).<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> Nach 2 Jahren</p>
<p>
<strong class="adsimple-321203183">Name:</strong> lang<br />
<strong class="adsimple-321203183">Wert:</strong> v=2&amp;lang=de-de<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie speichert Ihre voreingestellte bzw. bevorzugte Sprache.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-321203183">Name:</strong> lidc<br />
<strong class="adsimple-321203183">Wert:</strong> 1818367:t=1571904767:s=AQF6KNnJ0G321203183…<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie wird zum Routing verwendet. Routing zeichnet die Wege auf, wie Sie zu LinkedIn gekommen sind und wie Sie dort durch die Webseite navigieren.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 24 Stunden</p>
<p>
<strong class="adsimple-321203183">Name:</strong> rtc<br />
<strong class="adsimple-321203183">Wert:</strong> kt0lrv3NF3x3t6xvDgGrZGDKkX<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Zu diesem Cookie konnten keine näheren Informationen in Erfahrung gebracht werden.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 2 Minuten</p>
<p>
<strong class="adsimple-321203183">Name:</strong> JSESSIONID<br />
<strong class="adsimple-321203183">Wert:</strong> ajax:3212031832900777718326218137<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Es handelt sich hier um ein Session-Cookie, das LinkedIn verwendet, um anonyme Benutzersitzungen durch den Server aufrecht zu erhalten.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-321203183">Name:</strong> bscookie<br />
<strong class="adsimple-321203183">Wert:</strong> &#8220;v=1&amp;201910230812…<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Bei diesem Cookie handelt es sich um ein Sicherheits-Cookie. LinkedIn beschreibt es als Secure-Browser-ID-Cookie.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 2 Jahren</p>
<p>
<strong class="adsimple-321203183">Name:</strong> fid<br />
<strong class="adsimple-321203183">Wert:</strong> AQHj7Ii23ZBcqAAAA…<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Zu diesem Cookie konnten keine näheren Informationen gefunden werden.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 7 Tagen</p>
<p>
<strong class="adsimple-321203183">Anmerkung:</strong> LinkedIn arbeitet auch mit Drittanbietern zusammen. Darum haben wir bei unserem Test auch die beiden Google-Analytics-Cookies _ga und _gat erkannt.</p>
<h3 class="adsimple-321203183">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Grundsätzlich behaltet LinkedIn Ihre personenbezogenen Daten so lange, wie es das Unternehmen als nötig betrachtet, um die eigenen Dienste anzubieten. LinkedIn löscht aber Ihre personenbezogenen Daten, wenn Sie Ihr Konto löschen. In manchen Ausnahmefällen behaltet LinkedIn selbst nach Ihrer Kontolöschung einige Daten in zusammengefasster und anonymisierter Form. Sobald Sie Ihr Konto löschen, können andere Personen Ihre Daten innerhalb von einem Tag nicht mehr sehen. LinkedIn löscht die Daten grundsätzlich innerhalb von 30 Tagen. LinkedIn behält allerdings Daten, wenn es aus rechtlicher Pflicht notwendig ist. Daten, die keinen Personen mehr zugeordnet werden können, bleiben auch nach Schließung des Kontos gespeichert. Die Daten werden auf verschiedenen Servern in Amerika und vermutlich auch in Europa gespeichert.</p>
<h3 class="adsimple-321203183">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. In Ihrem LinkedIn-Konto können Sie Ihre Daten verwalten, ändern und löschen. Zudem können Sie von LinkedIn auch eine Kopie Ihrer personenbezogenen Daten anfordern.</p>
<p>So greifen Sie auf die Kontodaten in Ihrem LinkedIn-Profil zu:</p>
<p>Klicken Sie in LinkedIn auf Ihr Profilsymbol und wählen Sie die Rubrik „Einstellungen und Datenschutz“. Klicken Sie nun auf „Datenschutz“ und dann im Abschnitt „So verwendet LinkedIn Ihre Daten auf „Ändern“. In nur kurzer Zeit können Sie ausgewählte Daten zu Ihrer Web-Aktivität und Ihrem Kontoverlauf herunterladen.</p>
<p>Sie haben auch in Ihrem Browser die Möglichkeit, die Datenverarbeitung durch LinkedIn zu unterbinden. Wie oben bereits erwähnt, speichert LinkedIn die meisten Daten über Cookies, die in Ihrem Browser gesetzt werden. Diese Cookies können Sie verwalten, deaktivieren oder löschen. Je nachdem, welchen Browser Sie haben, funktioniert die Verwaltung etwas anders. Die Anleitungen der gängigsten Browser finden Sie hier:</p>
<p>
<a class="adsimple-321203183" href="https://support.google.com/chrome/answer/95647?tid=321203183" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321203183" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321203183" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
</p>
<p>Sie können auch grundsätzlich Ihren Browser dahingehend einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
<p>LinkedIn ist aktiver Teilnehmer des EU-U.S. Privacy Shield Frameworks. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter <a class="adsimple-321203183" href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0" target="_blank" rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0</a> erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch LinkedIn näherzubringen. Auf <a class="adsimple-321203183" href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy</a> erfahren Sie noch mehr über die Datenverarbeitung des Social-Media-Netzwerks LinkedIn.</p>
<h1 class="adsimple-321203183">XING Datenschutzerklärung</h1>
<p>Wir benutzen auf unserer Webseite Social-Plugins des Social-Media-Netzwerks Xing, der Firma Xing SE, Dammtorstraße 30, 20354 Hamburg, Deutschland. Durch diese Funktionen können Sie beispielsweise direkt über unsere Website Inhalte auf Xing teilen, sich über Xing einloggen oder interessanten Inhalten folgen. Sie erkennen die Plug-ins am Unternehmensnamen oder am Xing-Logo. Wenn Sie eine Webseite aufrufen, die ein Xing-Plug-in verwendet, können Daten an die &#8220;Xing-Server&#8221; übermittelt, gespeichert und ausgewertet werden. In dieser Datenschutzerklärung wollen wir Sie darüber informieren, um welche Daten es sich dabei handelt und wie Sie diese Datenspeicherung verwalten oder verhindern.</p>
<h3 class="adsimple-321203183">Was ist Xing?</h3>
<p>Xing ist ein soziales Netzwerk mit dem Hauptsitz in Hamburg. Das Unternehmen hat sich auf das Verwalten von beruflichen Kontakten spezialisiert. Das heißt, anders als bei andere Netzwerken, geht es bei Xing in erster Linie um berufliches Networking. Die Plattform wird oft für die Jobsuche verwendet oder um Mitarbeiter für das eigene Unternehmen zu finden. Darüber hinaus bietet Xing interessanten Content zu verschiedenen beruflichen Themen. Das globale Pendant dazu ist das amerikanische Unternehmen LinkedIn.</p>
<h3 class="adsimple-321203183">Warum verwenden wir Xing auf unserer Webseite?</h3>
<p>Es gibt mittlerweile eine Flut an Social-Media-Kanälen und uns ist durchaus bewusst, dass Ihre Zeit sehr kostbar ist. Nicht jeder Social-Media-Kanal eines Unternehmens kann genau unter die Lupe genommen werden. Daher wollen wir Ihnen das Leben so einfach wie möglich machen, damit Sie interessante Inhalte direkt über unsere Website auf Xing teilen oder folgen können. Mit solchen „Social-Plug-ins“ erweitern wir unser Service auf unserer Website. Darüber hinaus helfen uns die Daten, die von Xing gesammelt werden, auf der Plattform gezielte Werbemaßnahmen durchführen zu können. Das heißt unser Service wird nur Menschen gezeigt, die sich auch wirklich dafür interessieren.</p>
<h3 class="adsimple-321203183">Welche Daten werden von Xing gespeichert?</h3>
<p>Xing bietet den Teilen-Button, den Folgen-Button und den Log-in-Button als Plug-in für Websites an. Sobald Sie eine Seite öffnen, wo ein Social-Plug-in von Xing eingebaut ist, verbindet sich Ihr Browser mit Servern in einem von Xing verwendeten Rechenzentrum. Im Falle des Teilen-Buttons sollen – laut Xing – keine Daten gespeichert werden, die einen direkten Bezug auf eine Person herleiten könnten. Insbesondere speichert Xing keine IP-Adresse von Ihnen. Weiters werden im Zusammenhang mit dem Teilen-Button auch keine Cookies gesetzt. Somit findet auch keine Auswertung Ihres Nutzerverhaltens statt. Nähere Informationen dazu erhalten Sie über <a class="adsimple-321203183" href="https://www.xing.com/app/share%3Fop%3Ddata_protection?tid=321203183" target="_blank" rel="noopener noreferrer">https://www.xing.com/app/share%3Fop%3Ddata_protection.</a>
</p>
<p>Bei den anderen Xing-Plug-ins werden erst Cookies in Ihrem Browser gesetzt, wenn Sie mit dem Plug-in interagieren bzw. darauf klicken. Hier können personenbezogene Daten wie beispielsweise Ihre IP-Adresse, Browserdaten, Datum und Zeitpunkt Ihres Seitenaufrufs bei Xing gespeichert werden. Sollten Sie ein XING-Konto haben und angemeldet sein, werden erhobene Daten Ihrem persönlichen Konto und den darin gespeicherten Daten zugeordnet.</p>
<p>Folgende Cookies werden in Ihrem Browser gesetzt, wenn Sie auf den Folgen bzw. Log-in-Button klicken und noch nicht bei Xing eingeloggt sind. Bitte bedenken Sie, dass es sich hier um eine beispielhafte Liste handelt und wir keinen Anspruch auf Vollständigkeit erheben können:</p>
<p>
<strong class="adsimple-321203183">Name:</strong> AMCVS_0894FF2554F733210A4C98C6%40AdobeOrg<br />
<strong class="adsimple-321203183">Wert:</strong> 1<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um Identifikationen von Websitebesuchern zu erstellen und zu speichern.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-321203183">Name:</strong> c_<br />
<strong class="adsimple-321203183">Wert:</strong> 157c609dc9fe7d7ff56064c6de87b019321203183-8<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Zu diesem Cookie konnten wir keine näheren Informationen in Erfahrung bringen.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach einem Tag</p>
<p>
<strong class="adsimple-321203183">Name:</strong> prevPage<br />
<strong class="adsimple-321203183">Wert:</strong> wbm%2FWelcome%2Flogin<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie speichert die URL der vorhergehenden Webseite, die Sie besucht haben.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 30 Minuten</p>
<p>
<strong class="adsimple-321203183">Name:</strong> s_cc<br />
<strong class="adsimple-321203183">Wert:</strong> true<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Adobe Site Catalyst Cookie bestimmt, ob Cookies im Browser grundsätzlich aktiviert sind.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>
<strong class="adsimple-321203183">Name:</strong> s_fid<br />
<strong class="adsimple-321203183">Wert:</strong> 6897CDCD1013221C-39DDACC982217CD1321203183-2<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um einen eindeutigen Besucher zu identifizieren.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 5 Jahren</p>
<p>
<strong class="adsimple-321203183">Name:</strong> visitor_id<br />
<strong class="adsimple-321203183">Wert:</strong> fe59fbe5-e9c6-4fca-8776-30d0c1a89c32<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Das Besucher-Cookie enthält eine eindeutige Besucher-ID und die eindeutige Kennung für Ihren Account.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 2 Jahren</p>
<p>
<strong class="adsimple-321203183">Name:</strong>_session_id<br />
<strong class="adsimple-321203183">Wert: </strong>533a0a6641df82b46383da06ea0e84e7321203183-2<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie erstellt eine vorübergehende Sitzungs-ID, die als In-Session-Benutzer-ID verwendet wird. Das Cookie ist absolut notwendig, um die Funktionen von Xing bereitzustellen.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach Sitzungsende</p>
<p>Sobald Sie bei Xing eingeloggt bzw. Mitglied sind, werden definitiv weitere personenbezogene Daten erhoben, verarbeitet und gespeichert. Xing gibt auch personenbezogene Daten an Dritte weiter, wenn das für die Erfüllung eigener betriebswirtschaftlicher Zwecke nötig ist, Sie eine Einwilligung erteilt haben oder eine rechtliche Verpflichtung besteht.</p>
<h3 class="adsimple-321203183">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Xing speichert die Daten auf verschiedenen Servern in diversen Rechenzentren. Das Unternehmen speichert diese Daten bis Sie die Daten löschen bzw. bis zur Löschung eines Nutzerkontos. Das betrifft natürlich nur User, die bereits Xing-Mitglied sind.</p>
<h3 class="adsimple-321203183">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. Auch wenn Sie kein Xing-Mitglied sind, können Sie über Ihren Browser eine mögliche Datenverarbeitung unterbinden oder nach Ihren Wünschen verwalten. Die meisten Daten werden über Cookies gespeichert. Je nachdem, welchen Browser Sie haben, funktioniert die Verwaltung etwas anders. Die Anleitungen der gängigsten Browser finden Sie hier:</p>
<p>
<a class="adsimple-321203183" href="https://support.google.com/chrome/answer/95647?tid=321203183" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321203183" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321203183" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
</p>
<p>Sie können auch grundsätzlich Ihren Browser dahingehend einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
<p>Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Xing näherzubringen. Auf <a class="adsimple-321203183" href="https://privacy.xing.com/de/datenschutzerklaerung?tid=321203183" target="_blank" rel="noopener noreferrer">https://privacy.xing.com/de/datenschutzerklaerung</a> erfahren Sie noch mehr über die Datenverarbeitung des Social-Media-Netzwerks Xing.</p>
<h1 class="adsimple-321203183">Vimeo Datenschutzerklärung</h1>
<p>Wir verwenden auf unserer Website auch Videos der Firma Vimeo. Betrieben wird das Videoportal durch Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA. Mit Hilfe eines Plug-ins können wir Ihnen so interessantes Videomaterial direkt auf unserer Website anzeigen. Dabei können gewissen Daten von Ihnen an Vimeo übertragen werden. In dieser Datenschutzerklärung zeigen wir Ihnen, um welche Daten es sich handelt, warum wir Vimeo verwenden und wie Sie Ihre Daten bzw. die Datenübertragung verwalten oder unterbinden können.</p>
<h3 class="adsimple-321203183">Was ist Vimeo?</h3>
<p>Vimeo ist eine Videoplattform, die 2004 gegründet wurde und seit 2007 das Streamen von Videos in HD-Qualität ermöglicht. Seit 2015 kann auch in 4k Ultra HD gestreamt werden. Die Nutzung des Portals ist kostenlos, es kann allerdings auch kostenpflichtiger Content veröffentlicht werden. Im Vergleich zum Marktführer YouTube, legt Vimeo vorrangig Wert auf hochwertigen Content in guter Qualität. So bietet das Portal einerseits viele künstlerische Inhalte wie Musikvideos und Kurzfilme, andererseits aber auch wissenswerte Dokumentationen zu den unterschiedlichsten Themen.</p>
<h3 class="adsimple-321203183">Warum verwenden wir Vimeo auf unserer Webseite?</h3>
<p>Ziel unserer Webpräsenz ist es, Ihnen den bestmöglichen Content zu liefern. Und zwar so einfach zugänglich wie möglich. Erst wenn wir das geschafft haben, sind wir mit unserem Service zufrieden. Der Videodienst Vimeo unterstützt uns dieses Ziel zu erreichen. Vimeo bietet uns die Möglichkeit, Ihnen qualitativ hochwertige Inhalte direkt auf unserer Website zu präsentieren. Statt Ihnen nur einen Link zu einem interessanten Video zu geben, können Sie so das Video gleich bei uns ansehen. Das erweitert unser Service und erleichtert Ihnen den Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video-Content an.</p>
<h3 class="adsimple-321203183">Welche Daten werden auf Vimeo gespeichert?</h3>
<p>Wenn Sie auf unserer Website eine Webseite aufrufen, die ein Vimeo-Video eingebettet hat, verbindet sich Ihr Browser mit den Servern von Vimeo. Dabei kommt es zu einer Datenübertragung. Diese Daten werden auf den Vimeo-Servern gesammelt, gespeichert und verarbeitet. Unabhängig davon, ob Sie ein Vimeo-Konto haben oder nicht, sammelt Vimeo Daten über Sie. Dazu zählen Ihre IP-Adresse, technische Infos über Ihren Browsertyp, Ihr Betriebssystem oder ganz grundlegende Geräteinformationen. Weiters speichert Vimeo Informationen über welche Webseite Sie den Vimeo-Dienst nutzen und welche Handlungen (Webaktivitäten) Sie auf unserer Webseite ausführen. Zu diesen Webaktivitäten zählen beispielsweise Sitzungsdauer, Absprungrate oder auf welchen Button Sie auf unserer Webseite mit eingebauter Vimeo-Funktion geklickt haben. Diese Handlungen kann Vimeo mit Hilfe von Cookies und ähnlichen Technologien verfolgen und speichern.</p>
<p>Falls Sie als registriertes Mitglied bei Vimeo eingeloggt sind, können meistens mehr Daten erhoben werden, da möglicherweise mehr Cookies bereits in Ihrem Browser gesetzt wurden. Zudem werden Ihre Handlungen auf unsere Webseite direkt mit Ihrem Vimeo-Account verknüpft. Um dies zu verhindern müssen Sie sich, während des &#8220;Surfens&#8221; auf unserer Webseite, von Vimeo ausloggen.</p>
<p>Nachfolgend zeigen wir Ihnen Cookies, die von Vimeo gesetzt werden, wenn Sie auf einer Webseite mit integrierter Vimeo-Funktion, sind. Diese Liste erhebt keinen Anspruch auf Vollständigkeit und geht davon aus, dass Sie keinen Vimeo-Account haben.</p>
<p>
<strong class="adsimple-321203183">Name:</strong> player<br />
<strong class="adsimple-321203183">Wert:</strong> &#8220;&#8221;<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Einstellungen, bevor Sie ein eingebettetes Vimeo-Video abspielen. Dadurch bekommen Sie beim nächsten Mal, wenn Sie ein Vimeo-Video ansehen, wieder Ihre bevorzugten Einstellungen.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach einem Jahr</p>
<p>
<strong class="adsimple-321203183">Name</strong>: vuid<br />
<strong class="adsimple-321203183">Wert: </strong>pl1046149876.614422590321203183-4<strong class="adsimple-321203183">
<br />
<strong class="adsimple-321203183">Verwendungszweck: </strong>
</strong>Dieses Cookie sammelt Informationen über Ihre Handlungen auf Webseiten, die ein Vimeo-Video eingebettet haben.<strong class="adsimple-321203183">
<br />
<strong class="adsimple-321203183">Ablaufdatum: </strong>
</strong>nach 2 Jahren</p>
<p>
<strong class="adsimple-321203183">Anmerkung:</strong> Diese beiden Cookies werden immer gesetzt, sobald Sie sich auf einer Webseite mit einem eingebetteten Vimeo-Video befinden. Wenn Sie das Video ansehen und auf die Schaltfläche klicken, um beispielsweise das Video zu &#8220;teilen&#8221; oder zu &#8220;liken&#8221;, werden weitere Cookies gesetzt. Dabei handelt es sich auch um Drittanbieter-Cookies wie  _ga oder _gat_UA-76641-8 von Google Analytics oder _fbp von Facebook. Welche Cookies hier genau gesetzt werden, hängt von Ihrer Interaktion mit dem Video ab.</p>
<p>Die folgende Liste zeigt einen Ausschnitt möglicher Cookies, die gesetzt werden, wenn Sie mit dem Vimeo-Video interagieren:</p>
<p>
<strong class="adsimple-321203183">Name:</strong> _abexps<br />
<strong class="adsimple-321203183">Wert:</strong> %5B%5D<br />
<strong class="adsimple-321203183">Verwendungszweck: </strong>Dieses Vimeo-Cookie hilft Vimeo, sich an die von Ihnen getroffenen Einstellungen zu erinnern. Dabei kann es sich zum Beispiel um eine voreingestellte Sprache, um eine Region oder einen Benutzernamen handeln. Im Allgemeinen speichert das Cookie Daten darüber, wie Sie Vimeo verwenden.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach einem Jahr</p>
<p>
<strong class="adsimple-321203183">Name:</strong> continuous_play_v3<br />
<strong class="adsimple-321203183">Wert:</strong> 1<br />
<strong class="adsimple-321203183">Verwendungszweck: </strong>Bei diesem Cookie handelt es sich um ein Erstanbieter-Cookie von Vimeo. Das Cookie sammelt Informationen wie Sie das Vimeo-Service verwenden. Beispielsweise speichert das Cookie, wann Sie ein Video pausieren bzw. wieder abspielen.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach einem Jahr</p>
<p>
<strong class="adsimple-321203183">Name:</strong> _ga<br />
<strong class="adsimple-321203183">Wert:</strong> GA1.2.1522249635.1578401280321203183-7<br />
<strong class="adsimple-321203183">Verwendungszweck: </strong>Dieses Cookie ist ein Drittanbieter-Cookie von Google. Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Websitebesucher.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 2 Jahren</p>
<p>
<strong class="adsimple-321203183">Name:</strong> _gcl_au<br />
<strong class="adsimple-321203183">Wert:</strong> 1.1.770887836.1578401279321203183-3<br />
<strong class="adsimple-321203183">Verwendungszweck: </strong>Dieses Drittanbieter-Cookie von Google AdSense wird verwendet, um die Effizienz von Werbeanzeigen auf Websites zu verbessern.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 3 Monaten</p>
<p>
<strong class="adsimple-321203183">Name:</strong> _fbp<br />
<strong class="adsimple-321203183">Wert:</strong> fb.1.1578401280585.310434968<br />
<strong class="adsimple-321203183">Verwendungszweck:</strong> Das ist ein Facebook-Cookie. Dieses Cookie wird verwendet, um Werbeanzeigen bzw. Werbeprodukte von Facebook oder anderen Werbetreibenden einzublenden.<br />
<strong class="adsimple-321203183">Ablaufdatum:</strong> nach 3 Monaten</p>
<p>Vimeo nutzt diese Daten unter anderem, um das eigene Service zu verbessern, um mit Ihnen in Kommunikation zu treten und um eigene zielgerichtete Werbemaßnahmen zu setzen. Vimeo betont auf seiner Website, dass bei eingebetteten Videos nur Erstanbieter-Cookies (also Cookies von Vimeo selbst) verwendet werden, solange man mit dem Video nicht interagiert.</p>
<h3 class="adsimple-321203183">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Vimeo hat den Hauptsitz in White Plains im Bundesstaat New York (USA). Die Dienste werden aber weltweit angeboten. Dabei verwendet das Unternehmen Computersysteme, Datenbanken und Server in den USA und auch in anderen Ländern. Ihre Daten können somit auch auf Servern in Amerika gespeichert und verarbeitet werden. Die Daten bleiben bei Vimeo so lange gespeichert, bis das Unternehmen keinen wirtschaftlichen Grund mehr für die Speicherung hat. Dann werden die Daten gelöscht oder anonymisiert. Vimeo entspricht dem EU-U.S. Privacy Shield Framework und darf somit Daten von Usern aus der EU sammeln, nutzen und in die USA übertragen.</p>
<h3 class="adsimple-321203183">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben immer die Möglichkeit, Cookies in Ihrem Browser nach Ihren Wünschen zu verwalten. Wenn Sie beispielsweise nicht wollen, dass Vimeo Cookies setzt und so Informationen über Sie sammelt, können Sie in Ihren Browser-Einstellungen Cookies jederzeit löschen oder deaktivieren. Je nach Browser funktioniert dies ein bisschen anders. Bitte beachten Sie, dass möglicherweise nach dem Deaktivieren/Löschen von Cookies diverse Funktionen nicht mehr im vollen Ausmaß zur Verfügung stehen. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten bzw. löschen.</p>
<p>
<a class="adsimple-321203183" href="https://support.google.com/chrome/answer/95647?tid=321203183" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=321203183" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=321203183" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
</p>
<p>
<a class="adsimple-321203183" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=321203183" target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
</p>
<p>Falls Sie ein registriertes Vimeo-Mitglied sind, können Sie auch in den Einstellungen bei Vimeo die verwendeten Cookies verwalten.</p>
<p>Vimeo ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a class="adsimple-321203183" href="https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&amp;status=Active" target="_blank" rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&amp;status=Active</a>. Mehr über den Einsatz von Cookies bei Vimeo erfahren Sie auf <a class="adsimple-321203183" href="https://vimeo.com/cookie_policy?tid=321203183" target="_blank" rel="follow noopener noreferrer">https://vimeo.com/cookie_policy</a>, Informationen zum Datenschutz bei Vimeo können Sie auf <a class="adsimple-321203183" href="https://vimeo.com/privacy?tid=321203183" target="_blank" rel="follow noopener noreferrer">https://vimeo.com/privacy</a> nachlesen.</p>
<p style="margin-top:15px;">Quelle: Erstellt mit dem <a title="Datenschutz Generator Deutschland" href="https://www.adsimple.de/datenschutz-generator/" target="_blank" rel="follow" style="text-decoration:none;">Datenschutz Generator</a> von AdSimple in Kooperation mit <a href="https://www.hashtagmann.de" target="_blank" rel="follow" title="">hashtagmann.de</a>
</p>

<!--h1>Legal Disclosure</h1>
Information in accordance with Section 5 TMG
<br>
<br>
Ines Katharina
<br>
Fürstenrieder Str. 7
<br>
80687 München
<br>
<h1>Contact Information</h1>
Telephone: 017656517348
<br>
E-Mail: <a href="mailto:info@ineskatharina.de">info@ineskatharina.de</a>
<br>
Internet address: <a href="ineskatharina.de" target="_blank">ineskatharina.de</a>
<br>
<br>
<h1>Regulated / Independent Professions</h1>
Job title: Designer
<br>
<br>
<h1>Video and Image Sources</h1>
https://vimeo.com/neverestfilm
<br>
<br>
<h1>Disclaimer</h1>

<h3>Accountability for content</h3> 
<br>
The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents'
accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for 
our own content on these web pages. In this matter, please note that we are not obliged to monitor 
the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. 
Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per 
§§ 8 to 10 of the Telemedia Act (TMG).

<br><br>
<h3> Accountability for links</h3>
<br>
Responsibility for the content of 
external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were 
evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective 
link immediately.
<br>
<br>
<h3>Copyright</h3>
<br> Our web pages and their contents are subject to German copyright law. Unless 
expressly permitted by law, every form of utilizing, reproducing or processing 
works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. 
Individual reproductions of a work are only allowed for private use. 
The materials from these pages are copyrighted and any unauthorized use may violate copyright laws.

<br><br>
<i>Quelle: </i><a href="http://www.translate-24h.de" target="_blank">Übersetzungsdienst translate-24h.de</a> <br><br>

<br><br>
<h1>Privacy Policy</h1>

<p>
Your privacy is important to us. It is InesKatharina's policy to respect your privacy regarding any information we may collect from you across our website, http://ineskatharina.de, and other sites we own and operate.
<br><br>
We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
<br><br>
We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
<br><br>
We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
<br><br>
Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
<br><br>
You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
<br><br>
Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
<br><br>
This policy is effective as of 15 August 2020.
<br><br>
Privacy Policy created with GetTerms.
</p-->
