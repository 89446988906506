<h1 [class] = "(getDisplayCategory() == 'sm') ? 'h1-small' : 'h1-standard' ">How you can reach me</h1>
<h2 [class] = "(getDisplayCategory() == 'sm') ? 'h2-small' : 'h2-standard' ">Contact</h2>
<p>If you want to get in touch with me just send my an <a href="mailto:info@ineskatharina.de?subject=Mail%20from%20Website">Email</a>,
    or connect with me via <a href="https://www.linkedin.com/in/ineskatharinakossack/">LinkedIn</a>, 
    you can also send me a massage with <a href="https://www.xing.com/profile/InesKatharina_Kossack/">Xing</a>,
    or just follow me on <a href="https://www.behance.net/ineskossack">Behance</a>.</p>

<br>
<br>
<br>
<br>
<br>
